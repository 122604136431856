<template>
  <div>
    <vx-card :title="$t(resources.SendMoney.i18n) || resources.SendMoney.name">
      <vs-row vs-type="flex" vs-justify="center" class="mb-base">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <img
            src="../../assets/images/logo/BlockpayLogo.png"
            alt
            class="imag-blur"
          />
        </vs-col>
      </vs-row>
      <vs-divider class="mb-base">
        <h4>
          <strong>{{
            $t(resources.Transfer.i18n) || resources.Transfer.name
          }}</strong>
        </h4>
      </vs-divider>
      <div
        class="vx-row"
        v-if="currentDate.getHours() >= 13 && currentDate.getDay() == 3"
      >
        <h4 class="font-semibold mt-2 mb-base ml-3 text-danger text-center">
          {{ $t(alerts.MsgNotTransferEwallet.i18n) || alerts.MsgNotTransferEwallet.name }}.
        </h4>
      </div>
      <div class="vx-row" v-else>
        <div class="vx-col md:w-1/2 w-full mt-2 mb-2">
          <ul class="centerx">
            <li>
              <vs-checkbox v-model="isPayout">{{
                $t(resources.SendMoneyEwallet.i18n) ||
                resources.SendMoneyEwallet.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label"
              >{{ $t(resources.Origin.i18n) || resources.Origin.name }} -
              {{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label
            >
            <v-select
              v-model="accountId"
              :clearable="true"
              :options="accountOption"
              :reduce="(item) => item.id"
              label="nameWithNumber"
            />
          </div>
        </div>
        <div v-if="!isPayout" class="vx-col md:w-3/4 w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <label class="vs-input--label"
                  >{{
                    $t(resources.Destination.i18n) || resources.Destination.name
                  }}
                  -
                  {{
                    $t(resources.AccountNumber.i18n) ||
                    resources.AccountNumber.name
                  }}</label
                >
                <v-select
                  id="accountDestination"
                  @keyup.native="getByText('accountDestination')"
                  v-model="accountDestination"
                  :clearable="true"
                  :options="accountDestinationList"
                  :reduce="(item) => item.id"
                  label="nameWithNumber"
                  name="destination"
                  v-validate="'required'"
                  v-on:input="getContactInfo(`${accountId}`)"
                  :hint="`${accountId}`"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('destination')"
                  >{{ errors.first("destination") }}</span
                >
              </div>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  :label="$t(resources.Name.i18n) || resources.Name.name"
                  v-model="contactName"
                  disabled
                />
              </div>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  :label="$t(resources.Email.i18n) || resources.Email.name"
                  v-model="contactEmail"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  type="number"
                  :label="
                    $t(resources.DistributorID.i18n) ||
                    resources.DistributorID.name
                  "
                  v-model="accountDestination"
                  v-validate="'required'"
                  name="amount"
                />
              </div>
              <span class="text-danger text-sm" v-show="errors.has('amount')">{{
                errors.first("amount")
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              type="number"
              :label="$t(resources.Value.i18n) || resources.Value.name"
              v-model="amount"
              v-validate="'required|decimal:2'"
              name="amount"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{
            errors.first("amount")
          }}</span>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click.prevent="valid()">
            {{ $t(resources.Send.i18n) || resources.Send.name }}
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import database from "@/assets/resources/enums/database.json";
import { Validator } from "vee-validate";

export default {
  components: {
    "v-select": vSelect,
    Validator,
  },
  data() {
    return {
      database: database,
      alerts: alerts,
      resources: resources,
      contactId: JSON.parse(localStorage.getItem("userInfo")).id,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      accountId: "",
      accountOption: [],
      accountDestination: "",
      accountDestinationList: [],
      contactName: "",
      contactEmail: "",
      amount: "",
      isPayout: false,
    };
  },
  async created() {
    this.$vs.loading();

    await this.getAccounts();

    this.$vs.loading.close();
  },
  computed: {
    currentDate() {
      var date = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      var datetime = new Date(date);
      return datetime;
    },
  },
  methods: {
    getContactInfo() {
      let _data = this.accountDestinationList.find(
        (x) => x.id == this.accountDestination
      );
      this.contactName = _data.contactName;
      this.contactEmail = _data.contactEmail;
    },

    async getAccounts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Account/GetByContact/${this.$i18n.locale.toUpperCase()}/${
          this.contactId
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data.success && result.data.resultCode == 200) {
            this.accountOption = result.data.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.accountOption = [];
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getByText(destination) {
      let getElementId;
      if (destination == "accountDestination")
        getElementId = document.getElementById("accountDestination");

      let text = getElementId.__vue__.search;

      if (text.length >= 3) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }Account/GetByText/${this.$i18n.locale.toUpperCase()}/${text}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
          },
        }).then(
          (result) => {
            if (!result.data.success) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle",
              });
              this.accountDestinationList = [];
            } else {
              if (destination == "accountDestination")
                this.accountDestinationList = result.data.data;
            }
          },
          (error) => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        );
      }
    },

    valid() {
      this.$validator.validateAll().then(async (result) => {
        let _origin = this.accountOption.find((x) => x.id == this.accountId);
        let _destination = this.accountDestinationList.find(
          (x) => x.id == this.accountDestination
        );

        if (
          !this.isPayout &&
          _origin.typeAccountId == this.database.typeAccount.PositiveBalance &&
          _destination.typeAccountId !=
            this.database.typeAccount.PositiveBalance
        ) {
          this.$vs.dialog({
            color: "warning",
            title: this.$i18n.t("Alert"),
            text:
              "Sólo puedes pasar dinero entre cuentas de tipo saldos a favor (Convención).",
          });
        } else if (
          !this.isPayout &&
          _origin.typeAccountId == this.database.typeAccount.MainAccount &&
          _destination.typeAccountId ==
            this.database.typeAccount.PositiveBalance
        ) {
          this.$vs.dialog({
            color: "warning",
            title: this.$i18n.t("Alert"),
            text:
              "No se permite pasar dinero de una principal (Disponible) a una tipo saldos a favor (Convención).",
          });
        } else if (
          this.isPayout &&
          _origin.typeAccountId == this.database.typeAccount.PositiveBalance
        ) {
          this.$vs.dialog({
            color: "warning",
            title: this.$i18n.t("Alert"),
            text: `No se permite enviar dinero de una cuenta tipo "Saldos a Favor" a E-wallet.`,
          });
        } else if (result) {
          await this.send();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      });
    },

    async send() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BlockPay/SendMoney`,
        data: {
          originId: this.accountId,
          destinationId: this.accountDestination,
          amount: this.amount,
          createdBy: this.email,
          language: this.$i18n.locale.toUpperCase(),
          merchantOrigin: this.database.merchants.BlockPay,
          merchantDestination: this.isPayout
            ? this.database.merchants.Payout
            : this.database.merchants.BlockPay,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (result.data.success) {
            await this.sendEmail(result.data.response);
            this.clear();
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: result.data.response,
              accept: this.acceptDialog,
            });
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.response,
            });
          }
          this.$vs.loading.close();
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        }
      );
    },

    async sendEmail(message) {
      let emails = [];
      emails.push(this.email);

      let account = "";
      if (this.isPayout) account = `${this.accountDestination} (E-wallet)`;
      else {
        account = this.accountDestinationList.find(
          (x) => x.id == this.accountDestination
        ).accountNumber;
        emails.push(this.contactEmail);
      }

      let _data = {
        subject: this.$i18n.t("MsgTransferAccount").replace("{0}", account),
        message: message,
        language: this.$i18n.locale.toUpperCase(),
        createdBy: this.email,
        productType: 3,
        identifier: 0,
        addressesList: emails,
        entityId: 0,
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Emails/Template`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
        },
      });
    },

    clear() {
      this.accountId = "";
      this.accountDestination = "";
      this.contactName = "";
      this.contactEmail = "";
      this.amount = "";
    },

    acceptDialog() {
      this.$router.push({ path: "/blockPay/index" });
    },
  },
};
</script>